<template>
  <div class="">
    <!-- {{cadre}} -->
    <b-table
      :fields="fields"
      :items="cadre"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(status)="data">
        <!-- <b-badge v-if="data.value == 'Production'" variant="primary"> -->
        <!-- {{ data.value }} -->
        <!-- </b-badge> -->

        <!-- <b-badge v-else variant="secondary"> -->
        <span> {{ data.value ? data.value : "-" }}</span>
        <!-- </b-badge> -->
      </template>

      <template v-slot:cell(actions)="data">
        <table-actions
          :actions="data.value"
          :editMode="data.item.hasOwnProperty('editMode')"
          @showItem="$router.push({ name: 'show-principe-ref' })"
          @editItem="$router.push({ name: 'edit-principe-ref' })"
        />
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-principe-ref',
            params: {
              id: data.item.id,
              slug: data.item.slug,
            },
          }"
          >{{ data.value }}
        </b-link>
      </template>
      <template v-slot:cell(capacites)="data">
        <div class="text-center">
          <b-badge
            variant="primary"
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            @click="capacitelist(data.value, data.item.name)"
            >{{ data.value.length }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(fileCadres)="data">
        <div class="text-center">
          <b-badge
            variant="primary"
            pill
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            @click="display(data.value, data.item.name)"
            >{{ data.value.length }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(patrimoine)="data">
        <b-link
          v-if="data.value"
          :to="{
            name: 'show-patrimony',
            params: {
              id: data.value.id,
              slug: data.value.slug,
            },
          }"
          >{{ data.value ? data.value.name : "-" }}
        </b-link>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(couverture)="data">
        <b-progress
          :value="data.value"
          :max="100"
          height="1rem"
          show-value
        ></b-progress>
      </template>

      <template v-slot:cell(implementaion)="data">
        <b-badge variant="primary" pill>{{ data.value }}</b-badge>
      </template>

      <template v-slot:cell(livrable)="data">
        <b-badge variant="primary" pill>{{ data.value }}</b-badge>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
    <div v-if="showfile" to="modals-xyz-548">
      <modal @close="showfile = !showfile">
        <Filelist :items="data" :libelle="libelle" />
      </modal>
    </div>
    <div v-if="showlist" to="modals-xyz-548">
      <modal @close="showlist = !showlist">
        <CapaciteList :items="capacite" :libelle="name" />
      </modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
import Modal from "@/components/custom/Modal.vue";
import Filelist from "./Filelist.vue";
import CapaciteList from "./CapaciteList.vue";

export default {
  components: { TableActions, Filelist, Modal, CapaciteList },
  data: () => ({
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    loading: true,
    showfile: false,
    data: null,
    showlist: false,
    capacite: null,
    cadre: null,
  }),

  props: {
    items: Array,
    fields: Array,
    libelle: String,
  },
  created() {
    this.$store
      .dispatch("principe/fetchCadre", this.$route.params.id)
      .then(() => {
        this.loading = false;
      });
  },
  methods: {
  display  (id, name) {
      this.data = id;
      this.libelle = name;
      if (this.data.length) this.showfile = !this.showfile;
    },
    capacitelist(data, name) {
      if (data.length) {
        this.capacite = data;
        this.name = name;
        this.showlist = !this.showlist;
      }
    },
  },
  computed: {
    ...mapGetters("principe", ["CADRE"]),
    rows() {
      return this.cadre ? this.cadre.length : 0;
    },
  },
  watch: {
    CADRE() {
      this.cadre = this.CADRE.cadres.map((data) => ({
        ...data,
        capacites: data.capacites.map((data) => ({
          ...data,
          type: "Technique",
          name: data.name,
          niveau: data.niveau ? data.niveau : "-",
        })),
      }));
    },
  },
};
</script>

<style></style>
