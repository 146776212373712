<template>
  <div class="">
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto">Liste des Objets </span>

      <div class="d-flex justify-content-end">
        <CustomAddButton
          text="Ajouter un objet"
          @click="showAddCadre = true"
          :disabled="disabled"
        ></CustomAddButton>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      :fields="fields"
      :items="items"
      responsive
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty >
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template v-slot:cell(actions)="data">
        <table-actions
          :actions="disabled ? [] : data.value"
          :editMode="data.item.hasOwnProperty('editMode')"
          @showItem="$emit('')"
          @editItem="$emit('')"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import CustomAddButton from "@/components/custom/CustomAddButton";

export default {
  components: { TableActions, CustomAddButton },
  data: () => ({}),

  props: {
    items: Array,
    fields: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
