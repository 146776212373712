<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p class="h2">Ajouter cadre</p>
        <hr />
      </div>
    </div>
    <b-form class="row">
      <div class="col-md-6 col-12">
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Type cadre:</label>
          </div>
          <div class="col-md-8">
            <b-form-input type="text" placeholder="Type cadre" required></b-form-input>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Code:</label>
          </div>
          <div class="col-md-8">
            <b-form-input type="text" placeholder="Code" required></b-form-input>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Libellé:</label>
          </div>
          <div class="col-md-8">
            <b-form-input type="text" placeholder="Libellé" required></b-form-input>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label class="mb-0">Couverture projet:</label>
          </div>
          <div class="col-md-8">
            <simple-slider></simple-slider>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Type cadre:</label>
          </div>
          <div class="col-md-8">
            <b-form-input
              type="text"
              placeholder="Libellé initiative"
              required
            ></b-form-input>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Capacité technique:</label>
          </div>
          <div class="col-md-8">
            <b-form-input
              type="text"
              placeholder="Capacité technique"
              required
            ></b-form-input>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Statut:</label>
          </div>
          <div class="col-md-8">
            <b-form-input type="text" placeholder="Statut" required></b-form-input>
          </div>
        </div>
      </div>

      <div class="col-12">
        <hr />
        <b-button variant="secondary">Confirmer</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import SimpleSlider from "@/components/forms/sliders/simpleSlider.vue";

export default {
  components: {
    SimpleSlider,
  },
};
</script>

<style></style>
